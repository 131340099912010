/* variables */
:root {
  --max-width: 900px;
  --font-family: 'Quicksand', sans-serif;
  --ultra-light-weight: 300;
  --light-weight: 400;
  --light-medium-weight: 500;
  --medium-weight: 600;
  --heavy-weight: 700;
  --light-teal-accent: rgba(109,228,223,0.1);
  --light-yellow-accent: rgba(254, 201, 106, 0.05);
  --red: #e86e4d;
  --light-grey-accent: rgba(109,165,166, 0.1);
  --light-text-color: rgba(0,0,0,0.7);
  --extra-light-text-color: rgba(0,0,0,0.5);
}
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

h2 {
    font-family: var(--font-family);
    font-weight: var(--heavy-weight);
    font-size: 2.5em;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

h3, h4 {
    font-family: var(--font-family);
    font-weight: var(--light-weight);
    font-size: 3em;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    white-space: nowrap;
}

h4 {
    font-size: 2em;
    font-weight: var(--medium-weight);
}

p {
    font-family: var(--font-family);
    font-weight: var(--ultra-light-weight);
    font-size: 1.3em;
    line-height: 32px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Welcome Page */
.welcome {
    min-height: 600px;
    height: 600px;
    width: 100vw;
    padding-top: 75px;
    background-color: var(--light-teal-accent);
}

.welcome-container{
    min-height: 600px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 900px;
    margin: 0 auto;
}

.welcome-left{
    height: 90%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.welcome-left-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.welcome-left-logo-image{
    height: 50px;
    width: 50px;
    background-image: url('//d2beia7gtp5yjy.cloudfront.net/cdn_image/df/4a50e/33250465/24612/landing/v999/zic3i/FlagwSunBurstRounded.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.welcome-left-logo-title{
    margin-left: 10px;
}

.welcome-left-content{
    width: 75%;
}

.welcome-left-content > h3{

}

.welcome-left-content > p{
    margin-top: 40px;
}

.welcome-left-download{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-left-download-item{
    height: auto;
    max-width: 400px;
    width: 200px;
    margin-bottom: 20px;
}

.welcome-left-download-item-left {
    margin-right: 10px;
}

.welcome-left-download-item-right {
    margin-left: 10px;
}

.welcome-left-social {
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-left-social-item {
    height: 35px;
    width: 35px;
    margin: 0 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.social-youtube {
    background-image: url('//d2beia7gtp5yjy.cloudfront.net/cdn_image/colorize_fff|maxD_100/40683/33250465/24612/landing/v999/lkc4v/download.png');
}

.social-instagram {
    background-image: url('//d2beia7gtp5yjy.cloudfront.net/cdn_image/colorize_fff|maxD_100/7cd1d/33250465/24612/landing/v999/mr5t5/instagram2.png');
}

.social-twitter {
    background-image: url('//d2beia7gtp5yjy.cloudfront.net/cdn_image/colorize_fff|maxD_100/b0149/33250465/24612/landing/v999/3paqq/twitter-logo-png-transparent.png');
}

.social-facebook {
    background-image: url('//d2beia7gtp5yjy.cloudfront.net/cdn_image/colorize_fff|maxD_100/f27f9/33250465/24612/landing/v999/q7oo3/f_logo_RGB-Hex-Blue_512.png');
}

.welcome-right{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.welcome-right-image{
    height: 100%;
    width: auto;
}

/* Reviews Page */
.reviews {
    min-height: 30vh;
    width: 100vw;
    /* background-color: var(--light-grey-accent); */
    margin: 50px 0;
}

.reviews-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: var(--max-width);
    margin: 0 auto;
}

.reviews-review{
    padding: 25px;
    width: 75%;
    text-align: center;
}

.reviews-review-content{
    font-family: var(--font-family);
    font-weight: var(--light-weight);
    font-size: 1.2em;
    line-height: 1.5;
    color: var(--light-text-color);
}

.reviews-review-author{
    font-family: var(--font-family);
    font-weight: var(--light-weight);
    font-size: 1em;
    margin-top: 10px;
    color: var(--light-text-color);
}

.reviews-review-rating{
    width: 100px;
    margin-top: 10px;
    background-color: var(--red);
}

/* overview */
.overview {
    padding: 50px 0;
    width: 100%;
    background-color: var(--light-yellow-accent);
    margin-bottom: 100vh;
}

.overview-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.overview-content {
    width: 50%;
    text-align: center;
}

.overview-content > h4 {
    margin: 0;
}

.overview-content > p{
    color: var(--extra-light-text-color);
}

/* header */
.header {
    position: absolute;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: var(--font-family);
    font-weight: var(--light-weight);
    font-size: 1.25em;
    width: var(--max-width);
}

/* newsletter */

.newsletter {
    background-color: #6da6a5;
    padding: 50px;
}

.newsletter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.newsletter-title {
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: var(--light-medium-weight);
}

.newsletter-inputs > input {
    margin: 0 5px;
    width: 300px;
    padding: .5em 1em;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    outline: none;
}

.newsletter-inputs > button {
    margin: 0 5px;
    padding: .5em 1em;
    font-size: 1.2em;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #fec969;
}


/* footer */

.footer {
    padding: 50px;
    background-color: var(--light-teal-accent);
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer-social {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-social-item {
    background-color: black;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    margin: 0 10px;
}

.footer-legal {
    margin-top: 20px;
}

/* about */

.about {
    padding-top: 100px;
    max-width: 1000px;
    margin: 0 auto;
}

.about > h4 {
    font-size: 1.5em;
    color: rgba(0,0,0,0.8);
}

.about > p {
    line-height: 1.5;
    font-size: 1.2em;
    color: rgba(0,0,0,0.7);
}

@media(max-width: 1100px) {
    .welcome {
        height: inherit;
        min-height: 80vh;
    }

    .welcome-container {
        flex-direction: column;
        height: 100%;
    }

    .welcome-left {
        margin-top: 50px;
        margin-bottom: 50px;
        text-align: center;
        align-items: center;
        width: 75%;
    }

    .welcome-left-content > h3 {
        margin-top: 25px;
    }

    .welcome-left-download {
        margin-top: 50px;
    }

    .welcome-right-image {
        height: 75vh;
    }

    .about {
        padding: 75px 50px;
    }

    .newsletter-title {
        font-size: 1.2em;
        text-align: center;
    }

    .header-menu {
        justify-content: center;
    }

    .welcome-right {
        justify-content: center;
    }
}

@media(max-width: 600px) {

    .welcome-left {
        width: 100%;
    }

    .welcome-left-content > h3 {
        font-size: 2em;
        margin-bottom: 10px;
    }

    .welcome-left-content > p {
        font-size: 1.2em;
    }

    .welcome-left-download {
        width: 150px;
        margin-top: 25px;
        margin-bottom: 0px;
        flex-direction: column;
    }

    .welcome-left-download > a > img{
        margin: 0;
        margin-bottom: 5px;
    }

    .newsletter-inputs > input {
        width: calc(100% - 2em);
    }

    .newsletter-inputs {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media(max-width: 400px) {

    .welcome-left {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .welcome-left-content > h3 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    .welcome-left-content > p {
        font-size: 1em;
    }

    .welcome-left-download {
        width: 150px;
        margin-top: 25px;
        margin-bottom: 0px;
    }
    .welcome-right-image {
        height: 60vh;
    }

    .newsletter-inputs {
        flex-direction: column;
    }

    .newsletter-inputs > button {
        margin-top: 20px;
    }

    .about {
        padding: 75px 25px;
    }
}

@media(max-width: 450px) {

    .reviews-review-content {
        font-size: 1em;
    }

    .reviews-review-author {
        font-size: .8em;
    }

}
